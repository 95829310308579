function getUrlVars(url) {
    var vars = {};
    var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}


export default function addDefaultFilter(route, url) {
    let jq = route.queryParamMap.get('jq') || '{}';

    try {
        jq = JSON.parse(jq);
    } catch (e) {
        jq = {};
    }

    jq = Object.assign({}, getUrlVars(url), jq);
    url = url.split("?")[0];

    let keys = Object.keys(jq).filter(function (k) {
        return jq[k] != '';
    })

    let queryParams = '';
    if (typeof jq === 'object' && jq !== null) {
        queryParams = keys.map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(jq[k])
        }).join('&')
    }
    let final_url = url + (queryParams === "" ? "" : ((url.indexOf("?") === -1 ? "?" : "&") + queryParams));

    return final_url;
}
